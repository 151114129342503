import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import PageComponent from '../../components/PageComponent'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import ViewModel from '../helpers/ViewModel'
import Table from '../helpers/Table'
import { Dialog } from '@headlessui/react'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'
import Label from '../helpers/Label'
import { useNavigate, useParams } from 'react-router-dom'
import QaqcComponent from './QaqcComponent'
import PleaseWait from '../Common/PleaseWait'

function AddEditQaqc() {
    const { id } = useParams()
    document.title = 'SiteSmart - QA/QC Details'
    const tableHeading = ['Sr.No', 'Task Name', 'Action']
    const [loader, setLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [modalData, setModalData] = useState([])
    const navigate = useNavigate()
    const [errors, setErrors] = useState([])
    const [qaqcArray, setQaqcArray] = useState([]);

    const updateQaqcArray = (qaqc_name, checked, remark) => {
        let array = JSON.parse(JSON.stringify(qaqcArray));
        const updatedData = array.map(x => (x.qaqc_name === qaqc_name ? { ...x, check_mark: checked, remark: remark } : x));
        setQaqcArray(updatedData);
    }

    const [taskId, setTaskId] = useState([])

    const [formInfo, setFormInfo] = useState({
        work_name: '',
        wing_name: '',
        level: '',
        flat_no: '',
    })

    const handleSubmit = (e) => {
        setLoader(true)
        e.preventDefault()
        const payload = {
            work_name: formInfo.work_name,
            wing_name: formInfo.wing_name,
            level: formInfo.level,
            flat_no: formInfo.flat_no,
            task_name: taskId,
            checked_by: localStorage.getItem('CURRENT_USERID'),
            qaqc: qaqcArray,
        }

        const notification = {
            from_user: localStorage.getItem('CURRENT_USERID'),
            to_user: '2',
            message: 'You have a new QA/QC approval request...',
            module: 'QA/QC Management',
            link: '/qa-qc',
        }

        setViewModel(false)
        axiosClient.post(`store_MainQaqc`, payload)
            .then(({ data }) => {
                setLoader(false)
                data.status ? flasher.success(data.msg) :
                    flasher.warning(data.msg)
                data.status ? navigate('/qa-qc') : navigate('/add-qa-qc')
                data.status && (
                    axiosClient.post(`store_Notification`, notification)
                        .then(({ data }) => {
                            setLoader(false)
                        })
                        .catch(({ response }) => {
                            setErrors(response.data.errors)
                            setLoader(false)
                        })
                )
            })
            .catch(({ response }) => {
                setErrors(response.data.errors)
                setLoader(false)
            })
    }

    const [taskLists, setTaskLists] = useState('')
    const getTaskLists = () => {
        setLoader(true)
        formInfo.level && axiosClient.get(`/single_levelmaster_data/${formInfo.level}`)
            .then(({ data }) => {
                setTaskLists(data.Mulititasks)
                setLoader(false)
            })
    }

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }


    const deleteQAQC = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_task/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getTaskLists()
                    })
            }
        })
    }

    function openModalactivity(id) {
        setTaskId(id)
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_qaqc_master/${id}`)
            .then(({ data }) => {
                setModalData(data.Mulitiqaqc_checkpoints)
                let array = [];
                data.Mulitiqaqc_checkpoints.map(i => {
                    array.push({
                        qaqc_name: i.id,
                        check_mark: false,
                        remark: ''
                    })
                });
                setQaqcArray(array);
                setModalLoader(false)
            })
    }

    const [work, setWork] = useState([])
    const getWork = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setWork(data.lists)
                setLoader(false)
            })
    }

    const [wing, setWing] = useState([])
    const getWingLists = () => {
        formInfo.work_name && axiosClient.get(`/work_wise_wings/${formInfo.work_name}`)
            .then(({ data }) => {
                setWing(data.work_wise_wingdata)
            })
    }

    const [level, setLevel] = useState([]);
    const getLevelData = () => {
        formInfo.work_name && formInfo.wing_name && axiosClient.get(`/work_wing_wise_level/${formInfo.work_name}/${formInfo.wing_name}/`)
            .then(({ data }) => {
                setLevel(data.work_wing_wise_leveldata)
                setLoader(false)
            })
    }

    const [flatList, setFlatList] = useState([])
    const getFlatNoList = () => {
        formInfo.level && axiosClient.get(`level_wise_flat/${formInfo.wing_name}/${formInfo.level}`)
            .then(({ data }) => {
                setFlatList(data.level_wise_flat)
                setTimeout(() => {
                    getFlatDetails()
                }, 1000);
            })
    }

    const getFlatDetails = () => {
        var estimate_area = '';
        var natureOfFlat = '';

        formInfo.flat_no && flatList.map((data) => {
            return (
                formInfo.flat_no === data.id && (estimate_area = data.estimate_area, natureOfFlat = data.nature_of_flat)
            )
        })
        formInfo.flat_no &&
            setFormInfo(formInfo => ({
                ...formInfo,
                area: estimate_area,
                nature_of_flat: natureOfFlat,
            }))
    }

    useEffect(() => {
        getFlatDetails()
    }, [formInfo.flat_no])

    useEffect(() => {
        getWingLists()
    }, [formInfo.work_name])

    useEffect(() => {
        getLevelData()
    }, [formInfo.work_name && formInfo.wing_name])

    useEffect(() => {
        getFlatNoList();
    }, [formInfo.level])

    useEffect(() => {
        getTaskLists();
    }, [formInfo.level])

    useEffect(() => {
        getWork();
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'QA/QC '} button={
                    <LinkButton to={'/qa-qc'}>
                        <ArrowLeftCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <form>
                        <div className='grid grid-cols-1 md:grid-cols-4 gap-x-6 mt-4 mb-2 '>
                            <div className='ml-2'>
                                <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                <CustomSelect id={'work_name'} name={'work_name'} value={formInfo.work_name} onChange={handleChange} className={'w-[25%]'}>
                                    <option disabled value={''}>--- Select Work ---</option>
                                    {work.map((workData, workId) => {
                                        return (<Fragment key={workId}>
                                            {/* <option value={() => { const { id, work_name } = workData }}>{workData.work_name}</option> */}
                                            <option value={workData.id}>{workData.work_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                                <Validation error={errors.work_name} />
                            </div>
                            <div className='ml-2'>
                                <Label htmlFor={'wing_name'} labelText={'Wing'} className={'mb-1'} />
                                <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange} className={'w-[25%]'}>
                                    <option disabled value={''}>--- Select Wing ---</option>
                                    {wing && wing.map((wingData, wingId) => {
                                        return (<Fragment key={wingId}>
                                            <option value={wingData.id}>{wingData.wing_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                                <Validation error={errors.wing_name} />
                            </div>

                            <div className='ml-2'>
                                <Label htmlFor={'level'} labelText={'Level'} className={'mb-1'} />
                                <CustomSelect id={'level'} name={'level'} value={formInfo.level} onChange={handleChange} className={'w-[25%]'}>
                                    <option disabled value={''}>--- Select Level ---</option>
                                    {level && level.map((levelData, levelId) => {
                                        return (<Fragment key={levelId}>
                                            <option value={levelData.level}>{levelData.level_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                                <Validation error={errors.level} />
                            </div>
                            <div className='ml-2'>
                                <Label htmlFor={'flat_no'} labelText={'Flat No.'} className={'mb-1'} />
                                <CustomSelect id={'flat_no'} name={'flat_no'} value={formInfo.flat_no} onClick={() => getFlatDetails()} onChange={(e) => { handleChange(e); getFlatDetails() }}>
                                    <option disabled value={''}>--- Select Flat No ---</option>
                                    {flatList && flatList.map((flatData, flatId) => {
                                        return (<Fragment key={flatId}>
                                            <option value={flatData.id}>{flatData.flat_no}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                                <Validation error={errors.flat_no} />
                            </div>
                        </div>
                    </form>
                    {taskLists && taskLists.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {taskLists.map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.task_name}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <Link onClick={() => openModalactivity(tableData.task_id)}><Button className={'p-1.5'}><PlusCircleIcon className='w-5 h-5' /></Button></Link>
                                                {/* <Button onClick={() => deleteTask(tableData.id)} title={'Delete'} className={'p-1.5'}><TrashIcon className='w-5 h-5' /></Button> */}
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[15vh]'>
                            {/* <p className='text-[4vw] md:text-3xl font-bold'>Sorry! Data Not Found</p> */}
                            <p className='text-[4vw] md:text-3xl font-bold'>Select Above value to get Filtered Data</p>
                        </div>
                    )}
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
                            <Dialog.Panel className="w-auto max-w-screen-md transform ml-10 md:ml-0 overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <h1 className='flex justify-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>QA/QC Check Points</h1>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 mt-4 mb-2 '>
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-20 md:w-36 font-bold'>Work Name</h1>
                                                <h1>-</h1>
                                                {
                                                    formInfo.work_name && work.map((data, key) => {
                                                        return (
                                                            data.id === formInfo.work_name && data.work_name && <h1 key={key} className='w-36 ml-2'>{data.work_name}</h1>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-20 md:w-36 font-bold'>Wing Name</h1>
                                                <h1>-</h1>
                                                {
                                                    formInfo.wing_name && wing.map((data, key) => {
                                                        return (
                                                            data.id === formInfo.wing_name && data.wing_name && <h1 key={key} className='w-36 ml-2'>{data.wing_name}</h1>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-20 md:w-36 font-bold'>Level Name</h1>
                                                <h1>-</h1>
                                                {
                                                    formInfo.level && level.map((data, key) => {
                                                        return (
                                                            data.level === formInfo.level && data.level_name && <h1 key={key} className='w-36 ml-2'>{data.level_name}</h1>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-20 md:w-36 font-bold'>Flat No.</h1>
                                                <h1>-</h1>
                                                {
                                                    formInfo.flat_no && flatList.map((data, key) => {
                                                        return (
                                                            data.id === formInfo.flat_no && data.flat_no && <h1 key={key} className='w-36 ml-2'>{data.flat_no}</h1>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-20 md:w-36 font-bold'>Checked By</h1>
                                                <h1>-</h1>
                                                <h1 className='w-36 ml-2'>{localStorage.getItem('CURRENT_USERNAME')}</h1>
                                            </div>
                                        </div>
                                        <br />
                                        <div className='grid grid-cols-2 gap-x-6 mt-4 mb-3 '>
                                            <div className='flex shadow-md bg-gray-50 items-center justify-center gap-2 py-2'>
                                                <h1 className='font-bold'>Check Points</h1>
                                            </div>
                                            <div className='flex shadow-md bg-gray-50 items-center justify-center'>
                                                <h1 className='font-bold'>Remark</h1>
                                            </div>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div>
                                                {
                                                    modalData && modalData.map((data) => {
                                                        return (
                                                            <QaqcComponent updateQaqcArray={updateQaqcArray} data={data} />
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="mt-4  grid grid-cols-2 gap-1">
                                                <button
                                                    type="submit"
                                                    className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 md:px-4 md:py-2 md:text-sm text-[2vw] font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
                                                    Save
                                                </button>
                                                <Link id='gotit'
                                                    type="button"
                                                    className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 px-2 py-1 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                    onClick={() => setViewModel(false)}
                                                >
                                                    Got it, thanks!
                                                </Link>
                                            </div>
                                        </form>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )
            }

        </Fragment >
    )
}

export default AddEditQaqc
