import React, { Fragment, useEffect, useRef, useState } from 'react'
import { EyeIcon, PlusCircleIcon, PrinterIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import PageComponent from '../../components/PageComponent'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import ViewModel from '../helpers/ViewModel'
import Table from '../helpers/Table'
import { Dialog } from '@headlessui/react'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import moment from 'moment'
import ActionButton from '../helpers/ActionButton'
import { useReactToPrint } from 'react-to-print'
import Pagination from '../helpers/Pagination'

function StockTransfer() {
    document.title = 'SiteSmart - Stock Transfer'
    const tableHeading = ['Sr.No', 'Date', 'To Work', 'To Wing', 'To Level', 'Material', 'Unit', 'Transfered Qty', 'Action']
    const [loader, setLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [modalData, setModalData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const [transferList, setTransferList] = useState('')
    const getTransferList = () => {
        setLoader(true)
        axiosClient.get('/Stock_transfer_lists/')
            .then(({ data }) => {
                setTransferList(data.lists)
                setLoader(false)
            })
    }

    const deleteEmployee = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_Stock_transfer/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getTransferList()
                    })
            }
        })
    }
    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_Stock_transfer_data/${id}`)
            .then(({ data }) => {
                setModalData(data.single_data)
                setModalLoader(false)
            })
    }
    const tableRef = useRef();
    const modalRef = useRef();
    const handlePrintTable = useReactToPrint({
        content: () => tableRef.current,
    });
    const handlePrintModal = useReactToPrint({
        content: () => modalRef.current,
    });

    // let currentDate = new Date().toJSON().slice(0, 10);
    // const [date, setDate] = useState(currentDate)

    const [formInfo, setFormInfo] = useState({
        start_date: '',
        end_date: '',
    })

    const getdatewiseList = () => {
        formInfo.start_date && formInfo.end_date && axiosClient.get(`date_wisestock_consumeddata/${formInfo.start_date}/${formInfo.end_date}`,)
        .then(({ data }) => {
            getTransferList(data.lists)
         })
    }

    const handleDate = (e) => {
       // setDate(e.target.value);
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
      //  console.log(formInfo);

    useEffect(() => {
        getdatewiseList()
    }, [formInfo.end_date])

    useEffect(() => {
        getTransferList()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Stock Transefered List'} button={
                    <LinkButton to={'/stock-transfer/add-new-transfer'}>
                        <PlusCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Add Stock Transfer</span>
                    </LinkButton>
                }>
                    <div className='grid grid-cols-1 md:grid-cols-5 gap-x-1'>
                        <div>
                            <Label htmlFor={'date'} labelText={'Start Date'} className={'mb-1'} />
                            <Input id={'date'} type={'date'} name={'start_date'} onChange={handleDate} />
                        </div>
                        <div>
                            <Label htmlFor={'date'} labelText={'End Date'} className={'mb-1 '} />
                            <Input id={'date'} type={'date'} name={'end_date'} onChange={handleDate}/>
                        </div>
                        <div className=""></div>
                        <div className=""></div>
                        <div className="">
                            <Label htmlFor={'date'} labelText={'Print'} className={'mb-1 opacity-0'} />
                            <div className=' flex justify-end pt-2'>
                                <Link onClick={handlePrintTable} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent bg-primary-color px-7 py-2 text-sm font-medium text-white focus-visible:ring-offset-2 " ><PrinterIcon className='w-5 h-5' /></Link>
                            </div>
                        </div>
                    </div>
                    <br />
                    {transferList && transferList.length > 0 ? (
                        <div ref={tableRef}>
                            <Table tableHeading={tableHeading}>
                                {transferList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                    return (<Fragment key={tableDataID}>
                                        <tr className="bg-default-color text-gray-900 border-b text-center">
                                            <td className="px-4 py-3">{tableDataID + 1}</td>
                                            <td className="px-4 py-3">{moment(tableData.created_at).format('DD-MM-YYYY')}</td>
                                            <td className="px-4 py-3">{tableData.work_name}</td>
                                            <td className="px-4 py-3">{tableData.wing_name}</td>
                                            <td className="px-4 py-3">{tableData.level_name}</td>
                                            <td className="px-4 py-3">{tableData.material_name}</td>
                                            <td className="px-4 py-3">{tableData.material_unit}</td>
                                            <td className="px-4 py-3">{parseInt(tableData.transfer_qty).toLocaleString('en-IN')}</td>
                                            <td className="px-4 py-3">
                                                <div className='flex justify-center gap-3'>
                                                    <Link onClick={() => openModal(tableData.id)}><ActionButton className={'p-1.5 rounded-lg bg-primary'}><EyeIcon className='w-5 h-5 ' /></ActionButton></Link>
                                                    {/* <Link title='Edit' to={`/stock-transfer/edit-transfer/${tableData.id}`}><Button className={'p-1.5'}><PencilSquareIcon className='w-5 h-5' /></Button></Link> */}
                                                    {/* <Button onClick={() => deleteEmployee(tableData.id)} title={'Delete'} className={'p-1.5'}><TrashIcon className='w-5 h-5' /></Button> */}
                                                </div>
                                            </td>
                                        </tr>
                                    </Fragment>)
                                })}
                            </Table>
                        </div>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Transfer Data Not Found</p>
                        </div>
                    )}
                    {transferList && transferList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={transferList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? ('Please Wait') : (
                                    <Fragment>
                                        <div ref={modalRef} className='p-0 md:p-5'>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-wide'>Stock Transfer Information</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            <div className='mb-1 grid grid-cols-1 md:grid-cols-2 gap-2'>
                                                <div className="">
                                                    <div className='grid grid-cols-3 gap-2 mb-1 md:mb-2'>
                                                        <h1 className='font-bold '>Material</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData[0].material_name}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-2 mb-1 md:mb-2'>
                                                        <h1 className='font-bold '>Transferred from Work</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData[0].from_work_name}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-2 mb-1 md:mb-2'>
                                                        <h1 className='font-bold '>Transferred from Wing</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData[0].from_wing_name}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-2 mb-1 md:mb-2'>
                                                        <h1 className='font-bold '>Transferred from Level</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData[0].from_level_name}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-2 mb-1 md:mb-2'>
                                                        <h1 className='font-bold '>Transfer Quantity</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{parseInt(modalData[0].transfer_qty).toLocaleString('en-IN')}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-2 mb-1 md:mb-2'>
                                                        <h1 className='font-bold '>Stock Balance</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData[0].balance}</h1>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className='grid grid-cols-3 gap-2 mb-1 md:mb-2'>
                                                        <h1 className='font-bold '>Unit</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData[0].material_unit}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-2 mb-1 md:mb-2'>
                                                        <h1 className='font-bold '>Transferred  to Work</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData[0].to_work_name}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-2 mb-1 md:mb-2'>
                                                        <h1 className='font-bold '>Transferred  to Wing</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData[0].to_wing_name}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-2 mb-1 md:mb-2'>
                                                        <h1 className='font-bold '>Transferred  to Level</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData[0].to_level_name}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-2 mb-1 md:mb-2'>
                                                        <h1 className='font-bold '>Transferred Date</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{moment(modalData[0].created_at).format('DD-MM-YYYY')}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-2 mb-1 md:mb-2'>
                                                        <h1 className='font-bold '>Responsible Person</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData[0].resp_person}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4 grid grid-cols-3">
                                            <button id='gotit'
                                                type="button"
                                                className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 px-2 py-1 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setViewModel(false)}
                                            >
                                                Got it, thanks!
                                            </button>
                                            <div className=""></div>
                                            <Link id='printButton' onClick={handlePrintModal} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-2 py-1 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}

export default StockTransfer