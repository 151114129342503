import { createContext, useContext, useState } from "react";

const StateContext = createContext({
    currentUser: {},
    setCurrentUser: () => { },
    userToken: null,
    setUserToken: () => { },
    FRONTEND_URL: null,
    BACKEND_URL: null,
    IMG_URL: null,
});

export const ContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState({});
    const [userToken, _setUserToken] = useState(localStorage.getItem('ACCESS_TOKEN'));
    const setUserToken = (token) => {
        token ? localStorage.setItem('ACCESS_TOKEN', token) : localStorage.clear()
        _setUserToken(token)
    }

    // const FRONTEND_URL = 'http://localhost:3000';
    // const BACKEND_URL = 'http://localhost:8000';
    // const IMG_URL = 'http://localhost:8000';

    // Online  URL'S //

    //  const FRONTEND_URL = 'https://smartsugarfactory.com/';
    //  const BACKEND_URL = 'https://smartsugarfactory.com/SiteSmart/backend/api/';
    //  const IMG_URL = 'https://smartsugarfactory.com/SiteSmart/backend';

    // const FRONTEND_URL = 'https://sitesmart1.smartsugarfactory.com/';
    //   const BACKEND_URL = 'https://sitesmart1.smartsugarfactory.com/backend/api/';
    //   const IMG_URL = 'https://sitesmart1.smartsugarfactory.com/backend';

    const FRONTEND_URL = 'https://sitesmart2.smartsugarfactory.com/';
    const BACKEND_URL = 'https://sitesmart2.smartsugarfactory.com/backend/api/';
    const IMG_URL = 'https://sitesmart2.smartsugarfactory.com/backend';

    return (
        <StateContext.Provider value={{ currentUser, setCurrentUser, userToken, setUserToken, FRONTEND_URL, BACKEND_URL, IMG_URL }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext);