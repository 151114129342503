import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ArrowsUpDownIcon, CheckIcon, DocumentArrowUpIcon, DocumentIcon, TableCellsIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { NavLink } from 'react-router-dom'
import axiosClient from '../../Axios'
import PageComponent from '../../components/PageComponent'
import Loader from '../Common/Loader'
import Table from '../helpers/Table'
import CustomSelect from '../helpers/CustomSelect'
import Label from '../helpers/Label'
import { useReactToPrint } from 'react-to-print'
import moment from 'moment'
import Card from '../helpers/Card'
import { Menu, Transition } from '@headlessui/react'
import { CSVLink } from 'react-csv'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function QaqcReport() {
    document.title = 'SiteSmart -QA/QC Report'
    const tableHeading = ['Sr.No', 'QA/QC', 'Remark', 'status', 'Checked Date']
    const [loader, setLoader] = useState(false)

    const [formInfo, setFormInfo] = useState({
        work_name: '',
        wing_name: '',
        level: '',
        flat_no: '',
    })

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const [qaqcReportLists, setQaqcReportLists] = useState([])
    const getQaqcReport = () => {
        setLoader(true)
        axiosClient.get('/MainQaqc_report_lists')
            .then(({ data }) => {
                setQaqcReportLists(data.lists)
            })
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const headers = [
        { label: 'Sr.No', key: 'index' },
        { label: 'QA/QC', key: 'qaqc_checkpoints' },
        { label: 'Remark', key: 'wing_name' },
        { label: 'Status', key: 'level_name' },
        { label: 'Checked Date', key: 'material_name' },
    ];

    const csvData = qaqcReportLists && qaqcReportLists.map((item, index) => ({
        index: index + 1,
        qaqc_checkpoints: item.qaqc_checkpoints,
        wing_name: item.remark,
        level_name: item.check_mark,
        material_name: item.updated_at,
    }))

    const getWorkWiseLists = () => {
        setLoader(true)
        formInfo.work_name && axiosClient.get(`/workwise_MainQaqc_lists/${formInfo.work_name}`)
            .then(({ data }) => {
                setLoader(false)
                setQaqcReportLists(data.lists)
            })
    }

    const getWingWiseLists = () => {
        setLoader(true)
        formInfo.work_name && formInfo.wing_name && axiosClient.get(`/workwingwise_MainQaqc_lists/${formInfo.work_name}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLoader(false)
                setQaqcReportLists(data.lists)
            })
    }

    const getLevelWiseLists = () => {
        setLoader(true)
        formInfo.work_name && formInfo.wing_name && formInfo.level && axiosClient.get(`/workwinglavelwise_MainQaqc_lists/${formInfo.work_name}/${formInfo.wing_name}/${formInfo.level}`)
            .then(({ data }) => {
                setLoader(false)
                setQaqcReportLists(data.lists)
            })
    }

    const [work, setWork] = useState([])
    const getWork = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWork(data.lists)
            })
    }

    const [wing, setWing] = useState([])
    const getWingLists = () => {
        formInfo.work_name && axiosClient.get(`/work_wise_wings/${formInfo.work_name}`)
            .then(({ data }) => {
                setWing(data.work_wise_wingdata)
            })
    }

    const [level, setLevel] = useState([])
    const getLevelLists = () => {
        formInfo.work_name &&  formInfo.wing_name &&  axiosClient.get(`/work_wing_wise_level/${formInfo.work_name}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLevel(data.work_wing_wise_leveldata)
            })
    }

    const [flatList, setFlatList] = useState([])
    const getFlatNoList = () => {
        formInfo.level && axiosClient.get(`level_wise_flat/${formInfo.wing_name}/${formInfo.level}`)
            .then(({ data }) => {
                setFlatList(data.level_wise_flat)
            })
    }

    useEffect(() => {
        getWingLists()
        getWorkWiseLists()
    }, [formInfo.work_name])

    useEffect(() => {
        getLevelLists()
        getWingWiseLists()
    }, [formInfo.wing_name])

    useEffect(() => {
        getFlatNoList();
    }, [formInfo.level])

    useEffect(() => {
        getLevelWiseLists()
    }, [formInfo.level])

    useEffect(() => {
        getQaqcReport();
        getWork();
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'QA/QC Report'}
                >
                    <div className='grid grid-cols-2 md:grid-cols-5 gap-x-4 mt-4 mb-2'>
                        <div className=''>
                            <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                            <CustomSelect id={'work_name'} name={'work_name'} value={formInfo.work_name} onChange={handleChange} className={'w-[25%]'}>
                                <option value={''}>--- Select Work ---</option>
                                {work.map((workData, workId) => {
                                    return (<Fragment key={workId}>
                                        <option value={workData.id}>{workData.work_name}</option>
                                    </Fragment>)
                                })}
                            </CustomSelect>
                        </div>
                        <div className=''>
                            <Label htmlFor={'wing_name'} labelText={'Wing'} className={'mb-1'} />
                            <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange} className={'w-[25%]'}>
                                <option value={''}>--- Select Wing ---</option>
                                {wing && wing.map((wingData, wingId) => {
                                    return (<Fragment key={wingId}>
                                        <option value={wingData.id}>{wingData.wing_name}</option>
                                    </Fragment>)
                                })}
                            </CustomSelect>
                        </div>

                        <div className=''>
                            <Label htmlFor={'level'} labelText={'Level'} className={'mb-1'} />
                            <CustomSelect id={'level'} name={'level'} value={formInfo.level} onChange={handleChange} className={'w-[25%]'}>
                                <option value={''}>--- Select Level ---</option>
                                {level && level.map((levelData, levelId) => {
                                    return (<Fragment key={levelId}>
                                        <option value={levelData.level}>{levelData.level_name}</option>
                                    </Fragment>)
                                })}
                            </CustomSelect>
                        </div>
                        <div className=''>
                            <Label htmlFor={'flat_no'} labelText={'Flat No.'} className={'mb-1'} />
                            <CustomSelect id={'flat_no'} name={'flat_no'} value={formInfo.flat_no} onChange={(e) => { handleChange(e) }}>
                                <option value={''}>--- Select Flat No ---</option>
                                {flatList && flatList.map((flatData, flatId) => {
                                    return (<Fragment key={flatId}>
                                        <option value={flatData.id}>{flatData.flat_no}</option>
                                    </Fragment>)
                                })}
                            </CustomSelect>
                        </div>
                        <div className="">
                            <Menu as="div" className="">
                                <Label htmlFor={'date'} labelText={'Print'} className={'mb-1 opacity-0'} />
                                <div className="flex justify-end pt-1">
                                    <Menu.Button >
                                        <span className="sr-only">Open user menu</span>
                                        <p className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent hover:bg-primary-color bg-primary-color px-7 py-2 text-sm font-medium text-white focus-visible:ring-offset-2" ><ArrowsUpDownIcon className='w-5 h-5' /></p>
                                    </Menu.Button>
                                </div>

                                <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {/* <Menu.Item >
                                            {({ active }) => (
                                                <NavLink onClick={''} className={classNames(active ? 'bg-gray-200' : '', 'flex px-2 py-2 text-sm text-gray-700')}><TableCellsIcon className='w-8 h-5 pr-1' /> Import CSV</NavLink>
                                            )}
                                        </Menu.Item> */}
                                        <Menu.Item >
                                            {({ active }) => (
                                                <NavLink onClick={handlePrint} className={classNames(active ? 'bg-gray-200' : '', 'flex px-2 py-2 text-sm text-gray-700')}><DocumentIcon className='w-8 h-5 pr-1' /> Export PDF</NavLink>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            <div className="px-2 py-2">
                                                <CSVLink data={csvData} headers={headers} filename={'QAQC Report.csv'}>
                                                    <button className='flex'><DocumentArrowUpIcon className='w-8 h-5 pr-1' /> Export CSV</button>
                                                </CSVLink>
                                            </div>
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>

                    <div ref={componentRef} className='flex items-center justify-center '>
                        <Card className={' md:w-[100%] bg-default-color'}>
                            {
                                qaqcReportLists && qaqcReportLists.length > 0 ? (
                                    <div>
                                        {qaqcReportLists.map((data, ID) => {
                                            return (<Fragment key={ID}>
                                                <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] mt-4 tracking-widest'>{data.work_name} - QA/QC Info</h1>
                                                <hr className='mt-4 mx-4 border border-primary-color' />
                                                <div className='grid grid-cols-2 md:grid-cols-3 mt-4 gap-1 divide-x p-4'>
                                                    <div className="shadow-md">
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Task Name -</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.task_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Work Name</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.work_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Wing Name</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.wing_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Checked By</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.name}</h1>
                                                        </div>
                                                    </div>
                                                    <div className="shadow-md">
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Level</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.level_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Flat No.</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.flat_no}</h1>
                                                        </div>

                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Approve Status</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.approval_status}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Approve By</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.approve_by}</h1>
                                                        </div>
                                                    </div>
                                                    <div className="shadow-md">
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Approve Date</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{moment(data.updated_at).format('DD-MM-YYYY')}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Remark</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.remark}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    {data.QaqcCheckpoint_data && data.QaqcCheckpoint_data.length > 0 ? (
                                                        <div className="px-4">
                                                            {
                                                                <Table className='rounded-lg shadow-md bg-primary-color' tableHeading={tableHeading}>
                                                                    {data.QaqcCheckpoint_data && data.QaqcCheckpoint_data.map((tableData2, tableDataID2) => {
                                                                        return (<Fragment key={tableDataID2}>
                                                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                                                <td className="px-4 py-3">{(ID + 1) + ((tableDataID2 + 1) / 10)}</td>
                                                                                <td className="py-3">{tableData2.qaqc_checkpoints}</td>
                                                                                <td className="py-3">{tableData2.remark}</td>
                                                                                <td className="py-3">{tableData2.check_mark === '1' ?
                                                                                    (
                                                                                        <div className="flex justify-center">
                                                                                            <CheckIcon className='w-5 h-5 font-bold text-green-500' />
                                                                                        </div>
                                                                                    ) :
                                                                                    (
                                                                                        <div className="flex justify-center">
                                                                                            <XMarkIcon className='w-5 h-5 text-red-500' />
                                                                                        </div>
                                                                                    )}
                                                                                </td>
                                                                                <td>{moment(tableData2.updated_at).format('DD-MM-YYYY')}</td>
                                                                            </tr>
                                                                        </Fragment>)
                                                                    })}
                                                                </Table>
                                                            }
                                                        </div>
                                                    ) : (
                                                        <p>Loading...</p>
                                                    )}
                                                </div>
                                                <hr className='mt-4 border-4 border-gray-200' />
                                            </Fragment>)
                                        })}
                                    </div>
                                ) : (
                                    <div className='flex justify-center items-center h-[45vh]'>
                                        <p className='text-[4vw] md:text-3xl font-bold'>Sorry! Data Not Found</p>
                                    </div>
                                )
                            }
                        </Card>
                    </div>
                    <br /> <br />
                </PageComponent>
            )
            }
        </Fragment >
    )
}

export default QaqcReport