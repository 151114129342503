
import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon, TrashIcon } from '@heroicons/react/24/solid'
import axiosClient from '../../../Axios'
import flasher from '@flasher/flasher'
import { Link, useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../../components/PageComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import Button from '../../helpers/Button'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import CustomSelect from '../../helpers/CustomSelect'
import { Dialog, Transition } from '@headlessui/react'
import ViewModel from '../../helpers/ViewModel'
import Swal from 'sweetalert2'
import { useStateContext } from '../../../context/ContextProvider'
import WorkInfoSideMenu from '../../Common/WorkInfoSideMenu'
import Table from '../../helpers/Table'
import ActionButton from '../../helpers/ActionButton'
import PleaseWait from '../../Common/PleaseWait'

function AddMaterialEstimationDetails() {
    const { id, work_name, work_id } = useParams()
    {
        id ? (
            document.title = 'SiteSmart - Edit Material Estimation'
        ) : (
            document.title = 'SiteSmart - Add New Material Estimation'
        )
    }
    const [loader, setLoader] = useState(false)
    const [modalData, setModalData] = useState([])
    const [errors, setErrors] = useState([])
    const [modalLoader, setModalLoader] = useState(false)
    const navigate = useNavigate()
    const [viewModel, setViewModel] = useState(false)
    const { IMG_URL , FRONTEND_URL } = useStateContext()
    const tableHeading = ['Sr.No', 'Material Name', 'Unit', 'Estimated Quantity', 'Action']

    // Api to Get Work List And Work Id  //
    const [workname, setWorkName] = useState("")
    const [workid, setWorkId] = useState("")
    const getWorkDetails = () => {
        setLoader(true)
        axiosClient.get(`/get_work_details/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setWorkId(data.list.id)
                setWorkName(data.list)
            })
    }
    // Api to Get wing List  //
    const [wing, setWing] = useState([]);
    const getWingData = () => {
        workid && axiosClient.get(`/work_wise_wings/${workid}`)
            .then(({ data }) => {
                setLoader(false)
                setWing(data.work_wise_wingdata)
            })
    }
    // Api to Get Level List  //
    const [level, setLevel] = useState([])
    const getLevelLists = () => {
        setLoader(false)
        work_id && formInfo.wing_name && axiosClient.get(`/work_wing_wise_level/${work_id}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLoader(false)
                setLevel(data.work_wing_wise_leveldata)
            })
    }

    //Api to Get Material List  //
    const [material, setMaterial] = useState([]);
    const getMaterialData = () => {
        axiosClient.get('/material_lists')
            .then(({ data }) => {
                setLoader(false)
                setMaterial(data.lists)
            })
    }
    // Api to Get Unit List And Id  //
    const [units, setUnits] = useState([])
    const getUnitData = () => {
        inputs.material_name && axiosClient.get(`/material_wise_unit_data/${inputs.material_name}`)
            .then(({ data }) => {
                setLoader(false)
                setUnits(data.material_wise_unit_data)
            })
    }

    const [formInfo, setFormInfo] = useState({
        work_name: '' || work_name,
        wing_name: '',
        level: '',
    })

    const [tableData, setTableData] = useState([]);
    const materialest_id = id;
    const [inputs, setInputs] = useState({
        materialest_id: materialest_id,
        material_name: '',
        unit: units.material_unit,
        estimate_quantity: '',
    });
    console.log('inputs :', inputs);

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    // OnChange When We Input Data //
    const handleChangesub = (e) => {
        setInputs(inputs => ({
            ...inputs,
            [e.target.name]: e.target.value,
            unit: units.material_unit,
        }))
    }
    // Submit Modal Data //
    const handleSubmitsubtask = (e) => {
        e.preventDefault();
        //alert('hii');
        setTableData([...tableData, inputs]);
        setInputs({
            materialest_id: materialest_id,
            material_name: '',
            unit: units.material_unit,
            estimate_quantity: '',
        }
        );
        setViewModel(false)
    };
    
    // Api to Edit / Update Data //

    const handleUpdatesubtask = (e) => {
        e.preventDefault();
        setTableData([...tableData, inputs]);
        console.log("Inputs", inputs);
        axiosClient.post('/store_Est_Material', inputs)
            .then(({ data }) => {
                setLoader(false)
                //      getTask();
                flasher.success(data.msg)
                navigate(`/work-management/edit-materialestimation-details-form/${work_id}/${id}`)
                getSinglemasterialestData()
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            });

        setInputs({
            materialest_id: materialest_id,
            material_name: '',
            unit: '',
            estimate_quantity: '',
        }
        );
        setViewModel(false)
    };
    // Created State to store data in Array //
    const [materialname, setMaterialName] = useState([])
    const [unit, setUnit] = useState([])
    const [estimatequantity, setEstimateQuantity] = useState([])

    // Function for Convert Data into Array  //
    const handlechangeflatdetails = (e) => {
        const materialname = tableData && tableData.map((data) => {
            return data.material_name

        });
        setMaterialName(materialname)

        const unit = tableData && tableData.map((data) => {
            return data.unit
        });
        setUnit(unit)

        const estimatequantity = tableData && tableData.map((data) => {
            return data.estimate_quantity
        });
        setEstimateQuantity(estimatequantity)

    }
    // To Submit All Form //
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const payload = {
            work_name: workid,
            wing_name: formInfo.wing_name,
            level: formInfo.level,
            material_name: materialname,
            unit: unit,
            estimate_quantity: estimatequantity,
        }

        console.log("payload", payload);

        axiosClient.post(id ? `update_Material_estimation/${id}` : '/store_Material_estimation', payload)
            .then(({ data }) => {
                setLoader(false)
                data.status ? flasher.success(data.msg) :
                    flasher.warning(data.msg)
                data.status ? navigate(`/work-management/view-materialestimation-details/${work_id}`) : navigate(`/work-management/add-materialestimation-details-form/${work_id}`)
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }
    // Api to Get Single Data To View In Model //
    const getSinglemasterialestData = () => {
        setLoader(true)
        axiosClient.get(`/single_Material_estimation_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
                setModalData(data.Est_Material)
            })
    }
    // To Delete Material When We Add Static Data By Getting Index No. // 
    const handleDelete = (index) => {
        const filterData = tableData.filter((item, i) => i !== index)
        setTableData(filterData)
        console.log("data", filterData.dada);
    };

    // Function To Delete Single Data when Edit/Update Details //
    const deleteactivity = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_Est_Material/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getSinglemasterialestData()
                    })
            }
        })
    }
    // Modal //
    function openModal() {
        setViewModel(true)
        setModalLoader(true)
        setModalLoader(false)
    }

    useEffect(() => {
        getWingData();
    }, [workid])

    useEffect(() => {
        getLevelLists()
    }, [formInfo.wing_name])

    useEffect(() => {
        getUnitData()
    }, [inputs.material_name])

    useEffect(() => {
        if (id) {
            getSinglemasterialestData()
        }
        getWorkDetails();
        getMaterialData();
    }, [])

    useEffect(() => {
        handlechangeflatdetails();
    }, [tableData])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Material Estimation' : 'Add New Material Estimation'} button={
                    <LinkButton to={`/work-management/view-materialestimation-details/${work_id}`}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex gap-4'>
                        <div className='bg-default-color p-4 rounded w-[15%] md:w-[20%]'>
                        { workname.cad_image === null ? (<img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />
                        ) :(
                         workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />                           
                        )}
                            {/* <img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' /> */}
                            {/* {workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />} */}
                            <h1 className='text-center font-bold text-lg mt-2 mb-1 hidden md:block'># {workname.work_name}</h1>
                            <div className='flex flex-col gap-3 mt-3 md:mt-0'>
                                <WorkInfoSideMenu work_id={work_id} />
                            </div>
                        </div>
                        <div className='rounded w-[85%] md:w-[80%]'>
                            <div className='flex items-center justify-center'>
                                <Card className={'w-full md:w-[70rem] p-6  bg-default-color'}>
                                    <form onSubmit={handleSubmit}>
                                        <div className='grid grid-cols-1 md:grid-cols-3 gap-x-6 mb-3'>
                                            <div className='mb-3'>
                                                <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                                <Input id={'work_name'} type={'text'} name={'work_name'} value={workname.work_name} onChange={handleChange} readOnly={true} />
                                                <Validation error={errors.work_name} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'wing_name'} labelText={'Wing Name'} className={'mb-1'} />
                                                <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange}>
                                                    <option disabled value={''}>--- Choose wing Status ---</option>
                                                    {wing && wing.map((wingData, wingId) => {
                                                        return (<Fragment key={wingId}>
                                                            <option value={wingData.id}>{wingData.wing_name}</option>
                                                        </Fragment>)
                                                    })}
                                                </CustomSelect>
                                            </div>
                                            <div>
                                                <Label htmlFor={'level'} labelText={'Level'} className={'mb-1'} />
                                                <CustomSelect id={'level'} name={'level'} value={formInfo.level} onChange={handleChange} className={'w-full'}>
                                                    <option disabled value={''}>--- Choose level Name ---</option>
                                                    {level && level.map((levelData, levelId) => {
                                                        return (<Fragment key={levelId}>
                                                            <option value={levelData.level}>{levelData.level_name}</option>
                                                        </Fragment>)
                                                    })}
                                                </CustomSelect>
                                                <Validation error={errors.level} />
                                            </div>
                                        </div>
                                        <h3 className='text-2xl font-bold mb-4'>Add Material Estimation Details</h3>
                                        <button type='button' className={'w-[20%] group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color mb-4'} onClick={openModal}>
                                            <span>Add Material </span>
                                        </button>
                                        {
                                            id ? (
                                                modalData && modalData.length > 0 ? (
                                                    <Table tableHeading={tableHeading}>
                                                        {
                                                            modalData.map((item, i) => {
                                                                return (<Fragment key={i}>
                                                                    <tr className='mt-4 border-b'>
                                                                        <td className="px-4 py-3 text-center">{i + 1}</td>
                                                                        <td className='px-4 py-3 text-center text-lg'>{item.material_name}</td>
                                                                        <td className='px-4 py-3 text-center text-lg'>{item.unit}</td>
                                                                        <td className='px-4 py-3 text-center text-lg'>{Number(item.estimate_quantity).toLocaleString('en-IN')}</td>
                                                                        <td> <div className='flex justify-center items-center'><ActionButton className={'text-white bg-red-600 rounded-lg cursor-pointer'}><Link onClick={() => deleteactivity(item.id)} title={'Delete'}><TrashIcon className='w-5 h-5' /></Link></ActionButton> </div>
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>)
                                                            })}
                                                    </Table>
                                                ) : (
                                                    <div className='flex justify-center items-center h-[15vh]'>
                                                        <p className='text-[4vw] md:text-3xl font-bold'>Sorry! Data Not Found</p>
                                                    </div>
                                                )
                                            ) : (
                                                // View Table Data When We Add Material //
                                                tableData && tableData.length > 0 ? (
                                                    <Table tableHeading={tableHeading}>
                                                        {tableData.map((item, i) => {
                                                            return (<Fragment key={i}>
                                                                <tr className='mt-4 border-b'>
                                                                    <td className="px-4 py-3 text-center">{i + 1}</td>
                                                                    {
                                                                        item.material_name && material.map((data) => {
                                                                            return (
                                                                                item.material_name == data.id && data.material_name &&
                                                                                <td className='px-4 py-3 text-center text-lg'> {data.material_name}</td>
                                                                            )
                                                                        })
                                                                    }
                                                                    <td className=' px-4 py-3 text-center text-lg'> {item.unit}</td>
                                                                    <td className=' px-4 py-3 text-center text-lg'>{Number(item.estimate_quantity).toLocaleString('en-IN')}</td>
                                                                    <td>
                                                                        <div className='flex items-center justify-center'> <ActionButton className={'text-white bg-red-600 rounded-lg cursor-pointer'}><Link onClick={() => handleDelete(i)} title={'Delete'}><TrashIcon className='w-5 h-5' /></Link></ActionButton></div>
                                                                    </td>
                                                                </tr>
                                                            </Fragment>)
                                                        })}
                                                    </Table>
                                                ) : (
                                                    <div className='flex justify-center items-center h-[15vh]'>
                                                        <p className='text-[4vw] md:text-3xl font-bold'>Sorry! Data Not Found</p>
                                                    </div>
                                                )
                                            )
                                        }
                                        {/* <div className='flex justify-center mt-4'>
                                            <button type='submit' className={'w-56 group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color gap-3'}>
                                                <InboxArrowDownIcon className="h-5 w-5 text-default-color" />
                                                <span>Save Now</span>
                                            </button>
                                        </div> */}
                                        <Button className={'w-60 mx-auto mt-9'}>
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                            <span className='ml-5'>Save Now</span>
                                        </Button>

                                    </form>
                                </Card>
                            </div>
                            {
                                viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                                    <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                        {modalLoader ?  <PleaseWait/> : (
                                            <Fragment>
                                                <form>
                                                    <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                                                        <div>
                                                            <Label htmlFor={'material_name'} labelText={'Material Name'} className={'mb-1'} />
                                                            <CustomSelect id={'material_name'} name=       {'material_name'} value={formInfo.  material_name} onChange={handleChangesub}  className={'w-full'}>
                                                                <option value={''}>--- Choose Material Name ---</option>
                                                                {material.map((materialData, materialId) => {
                                                                    return (<Fragment key={materialId}>
                                                                        <option value={materialData.id}>{materialData.material_name}</option>
                                                                    </Fragment>)
                                                                })}
                                                            </CustomSelect>
                                                            <Validation error={errors.material_name}/>
                                                        </div>
                                                        <div className='mb-3'>
                                                            <Label htmlFor={'unit'} labelText={'Unit'} className={'mb-1'} />
                                                            <Input id={'unit'} type={'text'} name={'unit'} value={units.material_unit} onChange={handleChangesub} readOnly={true} />
                                                            <Validation error={errors.unit} />
                                                        </div>
                                                        <div className='mb-3'>
                                                            <Label htmlFor={'estimate_quantity'} labelText={'Estimate Quantity'} className={'mb-1'} />
                                                            <Input id={'estimate_quantity'} type={'text'} name={'estimate_quantity'} value={formInfo.estimate_quantity} onChange={handleChangesub} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                                            <Validation error={errors.estimate_quantity} />
                                                        </div>
                                                    </div>
                                                </form>
                                                {
                                                    id ? (
                                                        <div className='flex justify-center mt-4'>
                                                            <button type='submit' onClick={handleUpdatesubtask} className={'w-56 group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color gap-3'}>
                                                                <InboxArrowDownIcon className="h-5 w-5 text-default-color" />
                                                                <span>Save Now</span>
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div className='flex justify-center mt-4'>
                                                            <button type='submit' onClick={handleSubmitsubtask} className={'w-56 group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color gap-3'}>
                                                            <InboxArrowDownIcon className="h-5 w-5 text-default-color" />
                                                                <span>Save Now</span>
                                                            </button>
                                                        </div>

                                                    //      <Button className={'w-60 mx-auto mt-9'}>
                                                    //      <span type='submit' onClick={handleSubmitsubtask} className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                                    //      <span className='ml-5'>Save Now dd</span>
                                                    //  </Button>
                                                    )
                                                }
                                            </Fragment>
                                        )}
                                    </Dialog.Panel>
                                </ViewModel>
                            }
                        </div>
                    </div>
                </PageComponent>
            )}
        </Fragment>

    )
}


export default AddMaterialEstimationDetails
