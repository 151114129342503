import React, { useRef, Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { EyeIcon, PrinterIcon } from '@heroicons/react/24/solid'
import { useParams } from 'react-router-dom'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import { Link } from 'react-router-dom'
import flasher from '@flasher/flasher'
import { Dialog } from '@headlessui/react'
import Button from '../helpers/Button'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import ViewModel from '../helpers/ViewModel'
import CustomSelect from '../helpers/CustomSelect'
import Validation from '../helpers/Validation'
import { useReactToPrint } from "react-to-print";
import moment from 'moment'
import ActionButton from '../helpers/ActionButton'
import CurrencyInput from 'react-currency-input-field';
import Pagination from '../helpers/Pagination';
import PleaseWait from '../Common/PleaseWait'

function ContractorBillCreation() {
    document.title = 'SiteSmart - Bill Creation '
    const tableHeading = ['Sr.No', 'Work Name', 'Wing Name', 'Level Name', 'Task Name', 'Contractor Name', 'Status ', 'Bill Creation', 'Actions']
    const tableHeading1 = ['Sr.No', 'Bill No', 'Payment Type', 'Bill Date', 'Total Amount', 'Paid Amount', 'Balance ', 'Send To', 'Bill Description', 'Bill Remark', 'Download']
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const [modalLoader, setModalLoader] = useState(false)
    const [taskID, setTaskID] = useState('')
    const [cid, setCid] = useState("")
    const [billModel, setBillModel] = useState(false)
    const [printModel, setPrintModel] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [billCreationList, setBillCreationList] = useState('')
    const [installments, setInstallments] = useState("")
    const [modalData, setModalData] = useState([])
    const [modalData1, setModalData1] = useState([])
    const [balance, setBalance] = useState()
    const [total, setTotal] = useState('')
    const [workname, setWorkname] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handleChangeworkname = (e) => {
        setWorkname(e.target.value)
    }
    const [formInfo, setFormInfo] = useState({
        payment_type: '',
        send_to: '',
        bill_date: '',
        total_amount: '',
        paid_amount: '',
        balance: '',
        bill_remark: '',
        bill_description: ''
    })

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handlePaidAmount = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            paid_amount: e
        }))
    }


    const getBillCreationList = () => {
        setLoader(true)
        axiosClient.get('/Completed_task_Details')
            .then(({ data }) => {
                setLoader(false)
                setBillCreationList(data.lists)

            })
    }

    function openModal(id) {
        setViewModel(true)
        setModalLoader(false)
        axiosClient.get(`/single_Contractor_work_data/${id}`)
            .then(({ data }) => {
                setModalData(data.view_data)
                setTaskID(data.single_data.taskID)
                setInstallments(data.bill_data)
            })
    }

    function openModal1(id) {
        setFormInfo({
            payment_type: '',
            send_to: '',
            bill_date: '',
            total_amount: '',
            paid_amount: '',
            balance: '',
        })
        setBillModel(true)
        axiosClient.get(`/single_Contractor_work_data/${id}`)
            .then(({ data }) => {
                setModalData1(data.single_data)
                setBalance(data.balance_data)
                setCid(data.single_data.id)
                setTaskID(data.single_data.taskID)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('payment_type', formInfo.payment_type)
        formData.append('send_to', formInfo.send_to)
        formData.append('bill_date', formInfo.bill_date)
        formData.append('total_amount', total)
        if (formInfo.payment_type == "Full Payment") {
            formData.append('paid_amount', balance)
        } else {
            formData.append('paid_amount', formInfo.paid_amount)
        }
        if (formInfo.payment_type == "Full Payment") {
            formData.append('balance', "0")
        } else {
            formData.append('balance', formInfo.balance)
        }
        formData.append('bill_description', formInfo.bill_description)
        formData.append('bill_remark', formInfo.bill_remark)
        formData.append('task_id', taskID)
        formData.append('c_workid', cid)
        const paidAmount = parseInt(formInfo.paid_amount);
        const remainingBalance = parseInt(balance);
        if (paidAmount > remainingBalance) {
            flasher.error("Paid amount cannot be greater than the remaining balance.");
            setLoader(false)
            setBillModel(true)
            return;
        }
        else {
            const notification = {
                from_user: localStorage.getItem('CURRENT_USERID'),
                to_user: formInfo.send_to,
                message: 'You have a new Contractor Bill approval request...',
                module: 'Contractor Management',
                link: '/transactiondetails',
            }
            setLoader(true);

            axiosClient.post(`/store_Contratortaskbill`, formData)
                .then(({ data }) => {
                    setLoader(false)
                    flasher.success(data.msg)
                    setBillModel(false)
                    getBillCreationList();
                })
                .catch(({ response }) => {
                    setLoader(false)
                    setErrors(response.data.errors)
                })
            axiosClient.post(`store_Notification`, notification)
                .then(({ data }) => {
                    setLoader(false)
                })
                .catch(({ response }) => {
                    setErrors(response.data.errors)
                    setLoader(false)
                })
        }
    }

    function totalAmount() {
        if (modalData1.contract_type === "Fixed Rate") {
            setTotal(modalData1.fixed_rate_grand_total)
        } else {
            setTotal(modalData1.unit_based_grand_total)
        }
    }

    const [user, setUser] = useState([]);
    const getUserData = () => {
        axiosClient.get(`/user_lists`)
            .then(({ data }) => {
                setLoader(false)
                setUser(data.lists)
            })
    }

    const [works, setWorks] = useState([]);
    const getWorkData = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWorks(data.lists)
            })
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [printData, setPrintData] = useState("")
    function openModal2(id) {
        axiosClient.get(`/single_Contratortaskbill_data/${id}`)
            .then(({ data }) => {
                setPrintData(data.single_data)
            })
        setPrintModel(true)
    }

    const getAlldata = () => {
        workname && setLoader(true)
        workname == 'All' ? (getBillCreationList()) : axiosClient.get(`/work_wise_Completed_task_Details/${workname}`)
            .then(({ data }) => {
                setBillCreationList(data.lists)
                setLoader(false)
            })
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        totalAmount()
    }, [modalData1])

    useEffect(() => {
        getAlldata();
    }, [workname])

    useEffect(() => {
        getBillCreationList()
        getUserData()
        getWorkData()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Bill Creation'}>
                    <div className="grid grid-cols-1 md:grid-cols-4 mb-3">
                        <div>
                            <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                            <CustomSelect id={'work_name'} name={'work_name'} value={workname} onChange={handleChangeworkname} className={'w-full'}>
                                <option disabled value={''}>--- Choose Work Name ---</option>
                                <option value={"All"}>All</option>
                                {works.map((userData, userId) => {
                                    return (<Fragment key={userId}>
                                        <option value={userData.id}>{userData.work_name}</option>
                                    </Fragment>)
                                })}
                            </CustomSelect>
                        </div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {billCreationList && billCreationList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {billCreationList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3 whitespace-nowrap">{tableDataID + 1}</td>
                                        <td className="px-4 py-3 font-bold whitespace-nowrap">{tableData.work_name}</td>
                                        <td className="px-4 py-3 font-bold whitespace-nowrap">{tableData.wing_name}</td>
                                        <td className="px-4 py-3 font-bold whitespace-nowrap">{tableData.level_name}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{tableData.task_name}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{tableData.contractor_name}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{tableData.status}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center'>
                                                {tableData.remaining_bill === null ?
                                                    <button onClick={() => openModal1(tableData.id)} className='border-2  bg-red-700 border-red-700 text-white w-32 text-center px-3 py-1 rounded-lg'>Create Bill</button>
                                                    : tableData.remaining_bill == 0 ?
                                                        <button className='border-2  bg-green-500 border-green-500 text-white w-32 text-center px-3 py-1 rounded-lg'>Final Bill</button>
                                                        : <button onClick={() => openModal1(tableData.id)} className='border-2  bg-orange-400 border-orange-400 text-white w-32 text-center px-3 py-1 rounded-lg'>Bill Created</button>
                                                }
                                            </div>
                                        </td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <ActionButton onClick={() => openModal(tableData.id)} title='View' className={'p-1.5 bg-primary-color rounded-lg cursor-pointer text-white'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='md:text-3xl text-[4vw] font-bold'>Sorry!Data Not Found</p>
                        </div>
                    )}
                    {billCreationList && billCreationList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={billCreationList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        billModel && <ViewModel viewModel={billModel} setViewModel={setBillModel}>
                            <Dialog.Panel className="w-[70%] max-w-xl transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold text-xl tracking-wide'>Bill Creation</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        <form>
                                            <div className='mb-3 grid grid-cols-1 md:grid-cols-2 gap-4 '>
                                                <div>
                                                    <Label htmlFor={'payment_type'} labelText={'Payment Type'} className={'mb-1'} />
                                                    <CustomSelect id={'payment_type'} name={'payment_type'} value={formInfo.payment_type} onChange={handleChange}>
                                                        <option value={''}>--- Choose Payment Type ---</option>
                                                        <option value={'Full Payment'}>Full Payment</option>
                                                        <option value={'Advanced Payment'}>Advanced Payment</option>
                                                    </CustomSelect>
                                                    <Validation error={errors.payment_type} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'bill_date'} labelText={'Bill Date'} className={'mb-1'} />
                                                    <Input id={'bill_date'} type={'date'} name={'bill_date'} value={formInfo.bill_date} onChange={handleChange} />
                                                    <Validation error={errors.bill_date} />
                                                </div>
                                                {modalData1.contract_type === "Fixed Rate" ? (
                                                    <div >
                                                        <Label labelText={'Grand Total'} className={'mb-1'} />
                                                        <Input id={'total_amount'} type={'text'} name={'total_amount'} value={parseInt(modalData1.fixed_rate_grand_total).toLocaleString('en-IN')} readOnly={true} />
                                                    </div>)
                                                    : (<div >
                                                        <Label labelText={'Grand Total'} className={'mb-1'} />
                                                        <Input id={'total_amount'} type={'text'} name={'total_amount'} value={parseInt(modalData1.unit_based_grand_total).toLocaleString('en-IN')} readOnly={true} />
                                                    </div>)
                                                }
                                                <div>
                                                    <Label htmlFor={'paid_amount'} labelText={'Paid Amount'} className={'mb-1'} />
                                                    <CurrencyInput
                                                        className={"block w-full  rounded-md border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                                        id="paid_amount"
                                                        name="paid_amount"
                                                        value={formInfo.payment_type == "Full Payment" ? (balance) : (formInfo.paid_amount)}
                                                        onValueChange={handlePaidAmount} />
                                                    <Validation error={errors.paid_amount} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'balance'} labelText={'Remaining Balance'} className={'mb-1'} />
                                                    <Input id={'balance'} type={'text'} name={'balance'} value={formInfo.payment_type == "Full Payment" ? ("0") : (parseInt(formInfo.balance = balance - formInfo.paid_amount).toLocaleString('en-IN'))} onChange={handleChange} />
                                                    <Validation error={errors.balance} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'bill_description'} labelText={'Bill Description'} className={'mb-1'} />
                                                    <Input id={'bill_description'} type={'text'} name={'bill_description'} value={formInfo.bill_description} onChange={handleChange} />
                                                    <Validation error={errors.bill_description} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'bill_remark'} labelText={'Bill Remark'} className={'mb-1'} />
                                                    <Input id={'bill_remark'} type={'text'} name={'bill_remark'} value={formInfo.bill_remark} onChange={handleChange} />
                                                    <Validation error={errors.bill_remark} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'send_to'} labelText={'Send To'} className={'mb-1'} />
                                                    <CustomSelect id={'send_to'} name={'send_to'} value={formInfo.send_to} onChange={handleChange} className={'w-full'}>
                                                        <option value={''}>--- Choose Send To ---</option>
                                                        {user.map((userData, userId) => {
                                                            return (<Fragment key={userId}>
                                                                <option value={userData.id}>{userData.name} ({((userData.role).split('_')).join(' ')})</option>
                                                            </Fragment>)
                                                        })}
                                                    </CustomSelect>
                                                    <Validation error={errors.send_to} />
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                            <Button className={'w-full mt-6 md:mb-1 mb-5 mx-auto'}
                                                    type="submit"
                                                    onClick={(e) => handleSubmit(e)} >
                                                    Save Now
                                                </Button>
                                            </div>
                                        </form>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                    {
                        printModel && <ViewModel viewModel={printModel} setViewModel={setPrintModel}>
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <div className='m-5' ref={componentRef} id='print'>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-wide'>Bill Creation</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-3'>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Work Name</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{printData.work_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Wing</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{printData.wing_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Level</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{printData.level_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Task</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{printData.task_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Status</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{printData.status}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Contractor</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{printData.contractor_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Bill Number</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{printData.id}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Bill Date</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{moment(printData.bill_date).format('DD-MM-YYYY')}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Payment Type</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{printData.payment_type}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Grand Total</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{parseInt(printData.total_amount).toLocaleString('en-IN')}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Paid Amount</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{parseInt(printData.paid_amount).toLocaleString('en-IN')}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Balance</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{parseInt(printData.balance).toLocaleString('en-IN')}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Bill Description</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{printData.bill_description}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Bill Remark</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{printData.bill_remark}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Send To</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{printData.name}</h1>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4  grid grid-cols-3">
                                            <button id='gotit'
                                                type="button"
                                                className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setPrintModel(false)}>
                                                Got it, thanks!
                                            </button>
                                            <div className=""></div>
                                            <Link id='printButton' onClick={handlePrint} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <div className='flex justify-center items-center gap-4'>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[2vw] tracking-widest justify-center'>Contractor Payment Information</h1>
                                        </div>
                                        <hr className='my-4 border border-primary-color' />
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-3'>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Work Name</h1>
                                                <h1 className='flex justify-center '>-</h1>
                                                <h1>{modalData.work_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Wing Name</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.wing_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Level Name</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.level_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Task Name</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.task_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold whitespace-nowrap'>Contractor Name</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.contractor_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Status</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.status}</h1>
                                            </div>
                                        </div>
                                        <br></br>
                                        <h1 className='mb-4 font-bold md:text-xl text-[3vw]'>Payment Installments</h1>
                                        {installments && installments.length > 0 ? (
                                            <div className='w-[65vw] md:w-full'>
                                                <Table tableHeading={tableHeading1}>
                                                    {installments.map((tableData, tableDataID) => {
                                                        return (<Fragment key={tableDataID}>
                                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                                <td className="px-4 py-3 whitespace-nowrap">{tableDataID + 1}</td>
                                                                <td className="px-4 py-3 whitespace-nowrap">{tableData.id}</td>
                                                                <td className="px-4 py-3 whitespace-nowrap">{tableData.payment_type}</td>
                                                                <td className="px-4 py-3 whitespace-nowrap">{moment(tableData.bill_data).format('DD-MM-YYYY')}</td>
                                                                <td className="px-4 py-3 font-bold whitespace-nowrap">{parseInt(tableData.total_amount).toLocaleString('en-IN')}</td>
                                                                <td className="px-4 py-3 font-bold whitespace-nowrap">{parseInt(tableData.paid_amount).toLocaleString('en-IN')}</td>
                                                                <td className="px-4 py-3 font-bold whitespace-nowrap">{parseInt(tableData.balance).toLocaleString('en-IN')}</td>
                                                                <td className="px-4 py-3 whitespace-nowrap">{tableData.name}</td>
                                                                <td className="px-4 py-3  whitespace-nowrap">{tableData.bill_description}</td>
                                                                <td className="px-4 py-3  whitespace-nowrap">{tableData.bill_remark}</td>
                                                                <td className="px-4 py-3 whitespace-nowrap"><button onClick={() => openModal2(tableData.id)} className="print__button"> <Button className={'p-1.5'}><PrinterIcon className='w-5 h-5 print__button' /></Button></button></td>
                                                            </tr>
                                                        </Fragment>)
                                                    })}
                                            </Table>
                                            </div>
                                ) : (
                                <div className='flex justify-center items-center h-[40vh]'>
                                    <p className='md:text-3xl text-[4vw] font-bold'>Sorry!Bill Data Not Found</p>
                                </div>
                                        )}
                                <div className="mt-4  grid grid-cols-3">
                                    <button id='gotit'
                                        type="button"
                                        className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 md:py-2  py-3 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={() => setViewModel(false)} >
                                        Got it, thanks!
                                    </button>
                                    <div className=""></div>
                                    <div className=""></div>
                                </div>
                            </Fragment>
                                )}
                        </Dialog.Panel>
                        </ViewModel>
                    }
        </PageComponent>
    )
}
        </Fragment >
    )
}
export default ContractorBillCreation