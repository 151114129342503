import axios from 'axios';
import Router from './Router'

const axiosClient = axios.create({
 //baseURL : 'http://localhost:8000/api',
   
 // baseURL : 'https://smartsugarfactory.com/SiteSmart/backend/api/',
// baseURL : 'https://sitesmart1.smartsugarfactory.com/SiteSmart/backend/api/',
//  baseURL : 'https://sitesmart1.smartsugarfactory.com/backend/api/',
  baseURL : 'https://sitesmart2.smartsugarfactory.com/backend/api/',

})

axiosClient.interceptors.request.use((config)=>{
    config.headers.Authorization = `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`;
    return config;
})

axiosClient.interceptors.response.use(response =>{
    return response;
}, error => {
    if(error.response && error.response.status === 401){
        Router.navigate('/login');
        return error;
    }
    throw error;
})

export default axiosClient