import React, { Fragment, useEffect, useState } from 'react'
import { PencilSquareIcon, PlusCircleIcon } from '@heroicons/react/24/solid'
import Swal from 'sweetalert2'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import PageComponent from '../../components/PageComponent'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import Table from '../helpers/Table'
import { Dialog } from '@headlessui/react'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import { Link, useNavigate, useParams } from 'react-router-dom'
import QaqcComponent from './QaqcComponent'
import ViewModel from '../helpers/ViewModel'
import Pagination from '../helpers/Pagination'
import PleaseWait from '../Common/PleaseWait'

function TaskDetails(){
    document.title = 'SiteSmart - QA/QC Details'
    const tableHeading = ['Sr.No', 'Task Name', 'Work Name', 'Wing name', 'Level Name', 'Approval status', 'Action']
    const [remark, setRemark] = useState('')
    const [loader, setLoader] = useState(false)
    const [viewModal, setViewModal] = useState(false)
    const [approvalModal, setApprovalModal] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [modalData, setModalData] = useState([])
    const [workName, setWorkName] = useState('')
    const [wingName, setWingName] = useState('')
    const [levelName, setLevelName] = useState('')
    const [flatNo, setFlatNo] = useState('')
    const [checkedBy, setcheckedBy] = useState('')
    const [singleData, setSingleData] = useState('')
    const navigate = useNavigate()
    const [user, setUser] = useState([]);
    const [errors, setErrors] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const [updateId, setUpdateId] = useState()
    const [qaqcArray, setQaqcArray] = useState([]);
    const updateQaqcArray = (qaqc_name, checked, remark) => {
        let array = JSON.parse(JSON.stringify(qaqcArray));
        const updatedData = array.map(x => (x.checkpoint_id === qaqc_name ? { ...x, check_mark: checked, remark: remark } : x));
        setQaqcArray(updatedData);
    }

    const [sendTo, setSendTo] = useState('')
    const [taskId, setTaskId] = useState([])

    const [formInfo, setFormInfo] = useState({
        work_name: '',
        wing_name: '',
        level: '',
        flat_no: '',
    })

    const getUserData = () => {
        setLoader(true)
        axiosClient.get(`/user_lists`)
            .then(({ data }) => {
                setUser(data.lists)
                setLoader(false)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const payload = {
            work_name: workName,
            wing_name: wingName,
            level: levelName,
            flat_no: flatNo,
            task_name: taskId,
            checked_by: localStorage.getItem('CURRENT_USERID'),
            qaqc: qaqcArray
        }
        setViewModal(false)
        setLoader(true)
        axiosClient.post(`update_MainQaqc/${updateId}`, payload)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/qa-qc')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
        getDataForAllRows()
    }

    const handleApprovalSubmit = (status) => {
        setLoader(true)
        setApprovalModal(false)
        setRemark('')
        const payload = {
            approval_status: status,
            approve_by: localStorage.getItem('CURRENT_USERNAME'),
            remark: remark
        }

        const notification = {
            from_user: localStorage.getItem('CURRENT_USERID'),
            to_user: sendTo,
            message: 'QA/QC - ' + remark,
            module: 'QA/QC Approval',
            link: '/qa-qc',
        }

        axiosClient.post(`qaqc_approval/${singleData.id}`, payload)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/qa-qc')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
        axiosClient.post(`store_Notification`, notification)
            .then(({ data }) => {
                setLoader(false)
            })
            .catch(({ response }) => {
                setErrors(response.data.errors)
                setLoader(false)
            })
        getQaqcList()
    }

    const [qaqcList, setQaqcList] = useState()
    const getQaqcList = () => {
        setLoader(true)
        axiosClient.get(`/MainQaqc_lists`)
            .then(({ data }) => {
                data.lists == '' ? setLoader(false) : console.log()
                setQaqcList(data.lists)
            })
    }

    const getWorkWiseLists = () => {
        setLoader(true)
        formInfo.work_name && axiosClient.get(`/work_wing_lavel_wise_MainQaqc_lists/${formInfo.work_name}`)
            .then(({ data }) => {
                setLoader(false)
                setQaqcList(data.lists)
            })
    }

    const getWingWiseLists = () => {
        setLoader(true)
        formInfo.work_name && formInfo.wing_name && axiosClient.get(`/work_wing_lavel_wise_MainQaqc_lists/${formInfo.work_name}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLoader(false)
                setQaqcList(data.lists)
            })
    }

    const getLevelWiseLists = () => {
        setLoader(true)
        formInfo.work_name && formInfo.wing_name && formInfo.level && axiosClient.get(`/work_wing_lavel_wise_MainQaqc_lists/${formInfo.work_name}/${formInfo.wing_name}/${formInfo.level}`)
            .then(({ data }) => {
                setLoader(false)
                setQaqcList(data.lists)
            })
    }

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }


    const deleteQAQC = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_task/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getQaqcList()
                    })
            }
        })
    }

    function handleApprovalModal(id) {
        setApprovalModal(true)
        setModalLoader(true)
        axiosClient.get(`/single_MainQaqc_data/${id}`)
            .then(({ data }) => {
                setModalData(data.QaqcCheckpoint_data)
                setSingleData(data.single_data)
                setFlatNo(data.flat_data)
                setRemark(data.single_data.remark)
                setModalLoader(false)

            })
    }

    function openModalactivity(id) {
        setTaskId(id)
        setUpdateId(id)
        setViewModal(true)
        setModalLoader(true)
        axiosClient.get(`/single_MainQaqc_data/${id}`)
            .then(({ data }) => {
                setModalData(data.QaqcCheckpoint_data)
                setSingleData(data.single_data)
                setFlatNo(data.flat_data)

                let array = [];
                data.QaqcCheckpoint_data.map(i => {
                    array.push({
                        checkpoint_id: i.id,
                        qaqc_name: i.qaqc_name,
                        check_mark: i.check_mark,
                        remark: i.remark
                    })
                });
                setQaqcArray(array);
                setModalLoader(false)

            })
    }

    const [editButton, setEditbutton] = useState()
    const [qaqcCount, setQaqcCount] = useState()
    function openactivity(id){
        axiosClient.get(`/single_MainQaqc_data/${id}`)
            .then(({ data }) => {
                setEditbutton((prevModalData) => ({ ...prevModalData, [id]: data.QaqcCheckpoint_count }));
                setQaqcCount((prevModalData) => ({ ...prevModalData, [id]: data.QaqcCheckpoint_data.length }));
                setLoader(false)
            })
    }

    const [fetchedModalData, setFetchedModalData] = useState(false);
    const getDataForAllRows = async () => {
        try {
            const promises = qaqcList && qaqcList.map((rowData) => openactivity(rowData.id));
            await Promise.all(promises);
            setFetchedModalData(true);
        } catch (error) {
            console.error("Error fetching modal data:", error);
        }
    };

    const [work, setWork] = useState([])
    const getWork = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setWork(data.lists)
            })
    }

    const [wing, setWing] = useState([])
    const getWingLists = () => {
        formInfo.work_name && axiosClient.get(`/work_wise_wings/${formInfo.work_name}`)
            .then(({ data }) => {
                setWing(data.work_wise_wingdata)
            })
    }

    const [level, setLevel] = useState([]);
    const getLevelData = () => {
        formInfo.work_name && formInfo.wing_name && axiosClient.get(`/work_wing_wise_level/${formInfo.work_name}/${formInfo.wing_name}/`)
            .then(({ data }) => {
                setLoader(false)
                setLevel(data.work_wing_wise_leveldata)
            })
    }

    const [flatList, setFlatList] = useState([])
    const getFlatNoList = () => {
        formInfo.level && axiosClient.get(`level_wise_flat/${formInfo.wing_name}/${formInfo.level}`)
            .then(({ data }) => {
                setFlatList(data.level_wise_flat)
                setTimeout(() => {
                    getFlatDetails()
                }, 1000);
            })
    }

    const getFlatDetails = () => {
        var estimate_area = '';
        var natureOfFlat = '';

        formInfo.flat_no && flatList.map((data) => {
            return (
                formInfo.flat_no === data.id && (estimate_area = data.estimate_area, natureOfFlat = data.nature_of_flat)
            )
        })

        formInfo.flat_no &&
            setFormInfo(formInfo => ({
                ...formInfo,
                area: estimate_area,
                nature_of_flat: natureOfFlat,
            }))
    }

    useEffect(() => {
        getDataForAllRows();
    }, [qaqcList])

    useEffect(() => {
        getFlatDetails()
    }, [formInfo.flat_no])

    useEffect(() => {
        getWingLists()
        getWorkWiseLists()
    }, [formInfo.work_name])

    useEffect(() => { 
        getLevelData()
        getWingWiseLists()
    }, [formInfo.wing_name])

    useEffect(() => {
        getFlatNoList();
    }, [formInfo.level])

    useEffect(() => {
        getQaqcList();
        getLevelWiseLists()
    }, [formInfo.level])

    useEffect(() => {
        getWork();
        getQaqcList();
        getUserData();
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'QA/QC '} button={
                    <LinkButton to={'/qa-qc/add-qa-qc'}>
                        <PlusCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Add QA/QC</span>
                    </LinkButton>
                }>
                    <form>
                        <div className='grid grid-cols-2 md:grid-cols-4 gap-x-6 mt-4 mb-2 '>
                            <div className='ml-2'>
                                <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                <CustomSelect id={'work_name'} name={'work_name'} value={formInfo.         work_name} onChange={handleChange} className={'w-[25%]'}>
                                    <option disabled value={''}>--- Select Work ---</option>
                                    {work.map((workData, workId) => {
                                        return (<Fragment key={workId}>
                                            <option value={workData.id}>{workData.work_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                                <Validation error={errors.work_name}/>
                            </div>
                            <div className='ml-2'>
                                <Label htmlFor={'wing_name'} labelText={'Wing'} className={'mb-1'} />
                                <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.         wing_name} onChange={handleChange} className={'w-[25%]'}>
                                    <option disabled value={''}>--- Select Wing ---</option>
                                    {wing && wing.map((wingData, wingId) => {
                                        return (<Fragment key={wingId}>
                                            <option value={wingData.id}>{wingData.wing_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                                <Validation error={errors.wing_name} />
                            </div>

                            <div className='ml-2'>
                                <Label htmlFor={'level'} labelText={'Level'} className={'mb-1'} />
                                <CustomSelect id={'level'} name={'level'} value={formInfo.level} onChange={handleChange} className={'w-[25%]'}>
                                    <option disabled value={''}>--- Select Level ---</option>
                                    {level && level.map((levelData, levelId) => {
                                        return (<Fragment key={levelId}>
                                            <option value={levelData.level}>{levelData.level_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                                <Validation error={errors.level} />
                            </div>
                            <div className='ml-2'>
                                <Label htmlFor={'flat_no'} labelText={'Flat No.'} className={'mb-1'} />
                                <CustomSelect id={'flat_no'} name={'flat_no'} value={formInfo.flat_no} onClick={() => getFlatDetails()} onChange={(e) => { handleChange(e); getFlatDetails() }}>
                                    <option disabled value={''}>--- Select Flat No ---</option>
                                    {flatList && flatList.map((flatData, flatId) => {
                                        return (<Fragment key={flatId}>
                                            <option value={flatData.id}>{flatData.flat_no}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                                <Validation error={errors.flat_no} />
                            </div>
                        </div>
                    </form>
                    {qaqcList && qaqcList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {qaqcList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.task_name}</td>
                                        <td className="px-4 py-3">{tableData.work_name}</td>
                                        <td className="px-4 py-3">{tableData.wing_name}</td>
                                        <td className="px-4 py-3">{tableData.level_name}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center'>
                                                {editButton && editButton[tableData.id] !== 0 ? (
                                                    <button className='border-2 bg-red-500 border-red-500 text-white w-32 text-center px-3 py-1 rounded-lg'>Not Approved</button>

                                                ) : tableData.approval_status === 'Not_Approved' ? (<button onClick={() => handleApprovalModal(tableData.id)} className='border-2  bg-orange-400 border-orange-400 text-white w-32 text-center px-3 py-1 rounded-lg'>Not Approved</button>) : (
                                                    <button className='border-2 bg-green-500 border-green-500 text-white w-32 text-center px-3 py-1 rounded-lg'>Approved</button>
                                                )}
                                            </div></td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                {
                                                    qaqcCount && editButton && editButton[tableData.id] === qaqcCount[tableData.id] ?
                                                        <button className={'border-2  bg-red-500 border-red-500 text-white text-center p-1.5 rounded-lg'} onClick={() => openModalactivity(tableData.id)}><PencilSquareIcon className='w-5 h-5' /></button> : tableData.approval_status !== 'Approved' ?
                                                            <button className={' border-2  bg-orange-400 border-orange-400 text-white text-center p-1.5 rounded-lg'} onClick={() => openModalactivity(tableData.id)} ><PencilSquareIcon className='w-5 h-5' /></button> :
                                                            <button className={'border-2  bg-green-500 border-green-500 text-white text-center p-1.5 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></button>
                                                }
                                                {/* <Button onClick={() => deleteTask(tableData.id)} title ={'Delete'} className={'p-1.5'}><TrashIcon className='w-5 h-5' /></Button> */}
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[15vh]'>
                            {/* <p className='text-[4vw] md:text-3xl font-bold'>Sorry! Data Not Found</p> */}
                            <p className='text-3xl font-bold'>Select Above value to get Filtered Data</p>
                        </div>
                    )}
                    {qaqcList && qaqcList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={qaqcList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        viewModal && <ViewModel viewModel={viewModal} setViewModel={setViewModal}>
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>QA/QC Check Points</h1>
                                        <div className="flex justify-center">
                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 mt-4 mb-2 '>
                                                <div className='flex items-center gap-4 w-full py-2'>
                                                    <h1 className='w-[20vw] font-bold'>Work Name</h1>
                                                    <h1>-</h1>
                                                    <h1 className='w-[20vw] ml-2'>{singleData.work_name}</h1>
                                                </div>
                                                <div className='flex items-center gap-4 w-full py-2'>
                                                    <h1 className='w-[20vw] font-bold'>Wing Name</h1>
                                                    <h1>-</h1>
                                                    <h1 className='w-[20vw] ml-2'>{singleData.wing_name}</h1>
                                                </div>
                                                <div className='flex items-center gap-4 w-full py-2'>
                                                    <h1 className='w-[20vw] font-bold'>Level Name</h1>
                                                    <h1>-</h1>
                                                    <h1 className='w-[20vw] ml-2'>{singleData.level_name}</h1>
                                                </div>
                                                <div className='flex items-center gap-4 w-full py-2'>
                                                    <h1 className='w-[20vw] font-bold'>Flat No.</h1>
                                                    <h1>-</h1>
                                                    <h1 className='w-[20vw] ml-2'>{flatNo}</h1>
                                                </div>
                                                <div className='flex items-center gap-4 w-full py-2'>
                                                    <h1 className='w-[20vw] font-bold'>Checked By</h1>
                                                    <h1>-</h1>
                                                    <h1 className='w-[20vw] ml-2'>{singleData.name}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className='grid grid-cols-2 gap-x-6 mt-4 mb-3 '>
                                            <div className='flex shadow-md bg-gray-50 items-center justify-center gap-2 py-2'>
                                                <h1 className='font-bold'>Check Points</h1>
                                            </div>
                                            <div className='flex shadow-md bg-gray-50 items-center justify-center'>
                                                <h1 className='font-bold'>Remark</h1>
                                            </div>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div>
                                                {
                                                    modalData && modalData.map((data) => {
                                                        return (
                                                            <QaqcComponent updateQaqcArray={updateQaqcArray} data={data} />
                                                        )
                                                    })
                                                }
                                            </div>

                                            <div className="mt-4  grid grid-cols-2 gap-1">
                                                <button
                                                    type="submit"
                                                    className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 md:px-4 md:py-2 md:text-sm text-[2vw] font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
                                                    Save
                                                </button>
                                                <Link id='gotit'
                                                    type="button"
                                                    className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 px-2 py-1 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                    onClick={() => setViewModal(false)}
                                                >
                                                    Got it, thanks!
                                                </Link>
                                            </div>
                                        </form>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                    {
                        approvalModal && <ViewModel viewModel={approvalModal} setViewModel={setApprovalModal}>
                            <Dialog.Panel className="w-auto max-w-sm md:max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<div className="flex justify-center !important"><PleaseWait /></div>) : (
                                    <Fragment>
                                        <h1 className='flex items-center justify-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>QA/QC Check Points</h1>
                                        <div className='grid grid-cols-2 gap-x-2 mt-4 mb-2 '>
                                            <div className='flex items-center gap-4 w-full py-2'>
                                                <h1 className='w-[10vw] font-bold'>Work Name</h1>
                                                <h1>-</h1>
                                                <h1 className='w-[10vw] ml-2'>{singleData.work_name}</h1>
                                            </div>
                                            <div className='flex items-center gap-4 w-full py-2'>
                                                <h1 className='w-[10vw] font-bold'>Wing Name</h1>
                                                <h1>-</h1>
                                                <h1 className='w-[10vw] ml-2'>{singleData.wing_name}</h1>
                                            </div>
                                            <div className='flex items-center gap-4 w-full py-2'>
                                                <h1 className='w-[10vw] font-bold'>Level Name</h1>
                                                <h1>-</h1>
                                                <h1 className='w-[10vw] ml-2'>{singleData.level_name}</h1>
                                            </div>
                                            <div className='flex items-center gap-4 w-full py-2'>
                                                <h1 className='w-[10vw] font-bold'>Flat No.</h1>
                                                <h1>-</h1>
                                                <h1 className='w-[10vw] ml-2'>{flatNo}</h1>
                                            </div>
                                            <div className='flex items-center gap-4 w-full py-2'>
                                                <h1 className='w-[10vw] font-bold'>Checked By</h1>
                                                <h1>-</h1>
                                                <h1 className='w-[10vw] ml-2'>{singleData.name}</h1>
                                            </div>
                                        </div>
                                        <div className="pb-10">
                                            <div className='grid grid-cols-5 gap-x-1 mt-4'>
                                                <h1 className='font-bold flex justify-center'>QA/QC</h1>
                                                <h1 className='flex justify-center'></h1>
                                                <h1 className='font-bold flex justify-center '>Status</h1>
                                                <h1 className='flex justify-center'></h1>
                                                <h1 className='font-bold flex justify-center'>Remark</h1>
                                            </div>
                                            <hr className='border border-primary-color' />
                                            {
                                                modalData && modalData.map((data) => {
                                                    return (
                                                        <div >
                                                            <div className='grid grid-cols-5 gap-x-1 mt-4 mb-3 '>
                                                                <h1 className='font-bold flex justify-start'>{data.qaqc_checkpoints}</h1>
                                                                <h1 className='flex justify-center'>-</h1>
                                                                <h1 className='font-bold flex justify-center '>{data.check_mark === '1' ? 'Checked' : 'Not Checked'}</h1>
                                                                <h1 className='flex justify-center'>-</h1>
                                                                <h1 className='font-bold flex justify-center'>{data.remark}</h1>
                                                            </div>
                                                            <hr />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-2 mb-4'>
                                            <div>
                                                <Label htmlFor={'sendTo'} labelText={'Send To'} className={'mb-1'} />
                                                <CustomSelect id={'sendTo'} name={'sendTo'} value={sendTo} onChange={(e) => setSendTo(e.target.value)} >
                                                    <option disabled value={''}>--- Choose User Name ---</option>
                                                    {user.map((data, id) => {
                                                        return (<Fragment key={id}>
                                                            <option value={data.id}>{data.name} ({((data.role).split('_')).join(' ')})</option>
                                                        </Fragment>)
                                                    })}
                                                </CustomSelect>
                                                <Validation error={errors.sendTo} />
                                            </div>
                                            <div>
                                                <Label htmlFor={'remark'} labelText={'Approval Remark'} className={'mb-1'} />
                                                <Input id={'remark'} type={'text'} name={'remark'} value={remark} onChange={(e) => setRemark(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-3 gap-x-3 mt-4 mb-3 '>
                                            <button onClick={() => handleApprovalSubmit('Approved')} className='mb-1 border-2  bg-green-500 border-green-500 text-white text-center px-3 py-1 rounded-lg'>Approve</button>
                                            <button onClick={() => handleApprovalSubmit('Not_Approved')} className='mb-1 border-2  bg-red-500 border-red-500 text-white text-center px-3 py-1 rounded-lg'>Not Approve</button>
                                            <button className='mb-1 border-2  bg-red-500 border-red-500 text-white text-center px-3 py-1 rounded-lg' onClick={() => setApprovalModal(false)}>Close</button>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }

                </PageComponent>
            )
            }

        </Fragment >
    )
}

export default TaskDetails

